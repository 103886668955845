import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'vuejs-noty/dist/vuejs-noty.css'
import * as localData from '@/services/localData.js'
import VueNoty from 'vuejs-noty'
import Vuelidate from 'vuelidate'
import VueHtml2Canvas from 'vue-html2canvas'
import VueKeyCloak from '@dsb-norge/vue-keycloak-js'
import "./registerServiceWorker"

Vue.config.productionTip = false
Vue.config.devtools = true
Vue.use(Vuelidate)
Vue.use(VueNoty)
Vue.use(VueHtml2Canvas)

const setUserParams = (userParams) => {
  store.dispatch('user/setUserParams', userParams)
}

Vue.use(VueKeyCloak, {
  config: { 
    realm: process.env.VUE_APP_SSO_REALM,
    url: process.env.VUE_APP_SSO_HTTP_S + '://' + process.env.VUE_APP_SSO_HOST + '/auth/',
    clientAuthenticatorType: "client-secret",
    secret: "xU7MfY8fdI4nniBpwWP05JZsd2In5Q7c",
    clientId:process.env.VUE_APP_SSO_CLIENT_ID,
  },
  init: {
    onLoad: 'login-required',
    checkLoginIframe: false
  },
  onReady: kc => {
    setUserParams(Vue.prototype.$keycloak)
    new Vue({
      beforeCreate () {
        Vue.prototype.$http = axios
        //axios.defaults.headers.common['Authorization'] = `Bearer ${Vue.prototype.$keycloak.token}`
        axios.defaults.xsrfHeaderName = 'X-CSRFToken'
        axios.defaults.xsrfCookieName = 'csrftoken' 

      },
      router,
      store,
      // localData,
      render: (h) => h(App),
    }).$mount("#app");
  }
})

// new Vue({
//   beforeCreate () {
//     Vue.prototype.$http = axios
//     axios.defaults.headers.common['Authorization'] = "Bearer  " + localData.getlocalToken('auth_token')
//     axios.defaults.xsrfHeaderName = 'X-CSRFToken'
//     axios.defaults.xsrfCookieName = 'csrftoken' 
//   },
//   router,
//   store,
//   localData,
//   render: h => h(App)
// }).$mount('#app')

// const auth_token = localData.getlocalToken('auth_token')
// if (auth_token) {
//   localData.setlocalToken('auth_token',auth_token)
// }
